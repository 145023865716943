<template>
  <section>
    <div class="box p-4 mt-4">
      <div class="w-full items-center lg:justify-between lg:flex">
        <div class="flex gap-2 items-center text-xl font-bold">
          <i class="pi pi-pencil"></i>
          <p>Crear contrato</p>
        </div>
        <Button @click="crearContrato()" severity="success" label="Guardar" icon="pi pi-save" />
      </div>
      <div class="w-full mt-4">
        <div class="grid sm:grid-cols-1 md:grid-cols-6 gap-2">
          <div class="block gap-2">
            <label class="text-xs text-gray-600" for="valor_unitario">Contrato #</label>
            <InputText v-model="contratosStore.header.NumCto" class="w-full" />
            <span v-if="contratosStore.creando && !contratosStore._header.NumCto" class="text-xs text-red-400 italic">Este campo es requerido</span>
          </div>
          <div class="block gap-2">
            <label class="text-xs text-gray-600" for="cliente">Cliente</label>
            <Dropdown
              v-model="contratosStore.header.cliente"
              :options="contratosStore._listado_clientes"
              placeholder="Seleccione"
              optionLabel="CardName"
              optionValue="Id"
              class="w-full text-xs"
              filter
              showClear
              :inputStyle="{ padding: '0.65rem' }"
              inputClass="text-xs"
              panelClass="text-xs"
            />
            <span v-if="contratosStore.creando && !contratosStore._header.cliente" class="text-xs text-red-400 italic">Este campo es requerido</span>
          </div>
          <div class="block gap-2">
            <label class="text-xs text-gray-600" for="valor_unitario">Fecha inicio</label>
            <input v-model="contratosStore.header.fecha_inicio" :max="contratosStore.header.fecha_fin" @blur="validarFechasZonificacion('inicio')" :style="{ padding: '0.45rem' }" class="w-full border rounded-md border-gray-400" type="date">
            <span v-if="contratosStore.creando && !contratosStore._header.fecha_inicio" class="text-xs text-red-400 italic">Este campo es requerido</span>
          </div>
          <div class="block gap-2">
            <label class="text-xs text-gray-600" for="valor_unitario">Fecha fin</label>
            <input v-model="contratosStore.header.fecha_fin" :min="contratosStore.header.fecha_inicio" @blur="validarFechasZonificacion('fin')" :style="{ padding: '0.45rem' }" class="w-full border rounded-md border-gray-400" type="date">
            <span v-if="contratosStore.creando && !contratosStore._header.fecha_fin" class="text-xs text-red-400 italic">Este campo es requerido</span>
          </div>
          <div class="block gap-2">
            <label class="text-xs text-gray-600" for="valor_unitario">Valor del contrato</label>
            <InputNumber v-model="contratosStore.header.ValorTotal" class="w-full" inputId="currency-us" mode="currency" currency="COP" locale="es-CO" />
          </div>
          <div>
            <label class="text-xs text-gray-600" for="valor_unitario">Eps</label>
            <AutoComplete @item-select="agregarEps" v-model="epsSeleccionada" :suggestions="contratosStore._eps_encontradas" optionLabel="CardName" class="w-full text-xs" inputClass="text-xs w-full" :inputStyle="{'padding': '0.65rem'}" panelClass="text-xs" @complete="buscarEps($event)" />
            <div class="flex flex-wrap gap-1 mt-1 max-h-32 overflow-y-auto" v-if="contratosStore._header.Eps.length">
              <div style="height: min-content;" class="text-xs flex flex-wrap" v-for="eps in contratosStore._header.Eps" :key="eps">
                <div style="height: min-content;" class="p-1 rounded-md bg-blue-300 h-min text-blue-600 flex gap-1 items-center">
                  <button @click="eliminarEps(eps)"><i class="pi pi-times text-xs"></i></button>
                  <p>{{ eps.CardCode + ' - ' + eps.CardName }}</p>
                </div>
              </div>
            </div>
            <span v-if="contratosStore.creando && !contratosStore._header.Eps.length" class="text-xs text-red-400 italic">Este campo es requerido</span>
          </div>
        </div>
        <div class="w-full mt-2">
          <label class="text-xs text-gray-600" for="descripcion">Descripción del contrato</label>
          <Textarea id="descripcion" v-model="contratosStore.header.Descript" class="w-full" rows="2" cols="30" />
        </div>
      </div>
      <div class="w-full">
        <TabView>
          <TabPanel :motivosPadre="listadoMotivosAutorizacion" header="Modalidad/Motivos">
            <Motivos/>
          </TabPanel>
          <TabPanel header="Zonificación">
              <Zonificacion />
          </TabPanel>
          <TabPanel v-if="contratosStore._modalidad_contrato_seleccionada === '01'" header="Pacientes">
            <Pacientes />
          </TabPanel>
          <TabPanel header="Articulos/Precios">
            <ListaPrecio />
          </TabPanel>
          <TabPanel header="Soportes">
            <Soportes/>
          </TabPanel>
        </TabView>
      </div>
      <Dialog :closable="false" v-model:visible="modalBorrador" modal header="Creación contrato" :style="{ width: '40vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <div class="w-full mb-4">
          <label for="documento" class="text text-gray-600 italic">Elija como desea guardar el contrato</label>
        </div>
        <template #footer>
          <Button @click="guardarContrato(1)" label="Publicar" severity="success" icon="pi pi-save" />
          <Button @click="guardarContrato(2)" label="Guardar Borrador" severity="secondary" icon="pi pi-save" />
          <Button @click="modalBorrador = false" label="Cancelar" severity="danger" icon="pi pi-times" />
        </template>
      </Dialog>
    </div>
  </section>
</template>
<script>
  import { onMounted, ref, defineAsyncComponent } from 'vue'
  import { useContratosStore } from '../../stores/contratos.store'
  import { useRouter, useRoute } from 'vue-router'
  import { useToast } from 'primevue/usetoast'
  import Swal from 'sweetalert2'
  export default {
    name: 'configuracionContratosCrear',
    components: {
      Zonificacion: defineAsyncComponent(() => import('./components/zonificacion.vue')),
      Pacientes: defineAsyncComponent(() => import('./components/pacientes.vue')),
      ListaPrecio: defineAsyncComponent(() => import('./components/articulos.vue')),
      Soportes: defineAsyncComponent(() => import('./components/soportes.vue')),
      Motivos: defineAsyncComponent(() => import('./components/motivos.vue'))
    },
    setup () {
      const contratosStore = useContratosStore()
      const router = useRouter()
      const route = useRoute()
      const toast = useToast()
      const modalBorrador = ref(false)
      const listadoMotivosAutorizacion = ref([])
      const listadoRegimenes = ref([])
      const regimenSeleccionado = ref()
      const regimenesSeleccionados = ref([])
      const epsSeleccionada = ref()
      const ingresarRegimen = () => {
        listadoRegimenes.value = listadoRegimenes.value.filter(a => a.code !== regimenSeleccionado.value.code)
        regimenesSeleccionados.value.push(regimenSeleccionado.value)
        regimenSeleccionado.value = ''
      }
      const buscarEps = (event) => {
        contratosStore.buscarEps({ CardName: event.query })
      }
      const agregarEps = () => {
        contratosStore.header.Eps.push(epsSeleccionada.value)
        epsSeleccionada.value = ''
      }
      const eliminarRegimen = (codigo) => {
        listadoRegimenes.value.push(regimenesSeleccionados.value.find(a => a.code === codigo))
        regimenesSeleccionados.value = regimenesSeleccionados.value.filter(a => a.code !== codigo)
      }
      const motivosAutorizacionFiltrados = () => {
        listadoMotivosAutorizacion.value = []
        for (const i of contratosStore._listado_motivos_autorizacion) {
          if (i.CodModContrato === contratosStore._modalidad_contrato_seleccionada && !contratosStore.header.MotivosAutorizacion.some(a => a.Id === i.Id)) {
            listadoMotivosAutorizacion.value.push(i)
          }
        }
      }
      const crearContrato = () => {
        if (!contratosStore._header.cliente) return toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Debe ingresar un cliente para continuar', life: 3000 })
        if (!contratosStore._header.MotivosAutorizacion.length) return toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Debe ingresar un motivo para continuar', life: 3000 })
        if (!contratosStore._header.Eps.length) return toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Debe ingresar una eps para continuar', life: 3000 })
        if (!contratosStore._header.fecha_inicio) return toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Debe ingresar una fecha inicio para continuar', life: 3000 })
        if (!contratosStore._header.fecha_fin) return toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Debe ingresar una fecha fin para continuar', life: 3000 })
        contratosStore.validarContratos().then((data) => {
          if (data) {
            return Swal.fire({
              title: 'Advertencia',
              text: `
              Actualmente cuentas con un contrato activo con el Cliente ${data.Cliente.CardName}
              y con los motivos de autorización ${contratosStore._header.MotivosAutorizacion.map(a => a.Nombre)}.
              ¿Desea continuar con la creación del contrato?
              `,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Continuar',
              cancelButtonText: 'Cancelar'
            }).then((result) => {
              if (result.isConfirmed) {
                modalBorrador.value = true
              }
            })
          } else {
            modalBorrador.value = true
          }
        })
      }
      const guardarContrato = (tipo) => {
        contratosStore.crear(tipo).then((data) => {
          if (data === 'Faltan datos') return
          if (data) {
            if (typeof data === 'string') {
              return Swal.fire({
                icon: 'warning',
                title: 'Validacion Contrato',
                text: data
              })
            } else {
              return Swal.fire({
                icon: 'success',
                title: 'Creacion Contrato',
                text: 'Se creó el contrato exitosamente'
              }).then(() => {
                router.push({ name: 'pharmasan.ventas.contrato-precio.listado' })
              })
            }
          } else {
            return Swal.fire({
              icon: 'warning',
              title: 'Creacion Contrato',
              text: 'Ha ocurrido un error, valida la infomación ingresada'
            })
          }
        })
        modalBorrador.value = false
      }
      const eliminarEps = (data) => {
        contratosStore.header.Eps = contratosStore._header.Eps.filter(a => a.CardCode !== data.CardCode)
      }
      const validarFechasZonificacion = (type) => {
        if (type === 'inicio' && contratosStore._zonificaciones.length === 1) {
          contratosStore.zonificaciones[0].fecha_inicio = contratosStore._header.fecha_inicio
        }
        if (type === 'fin' && contratosStore._zonificaciones.length === 1) {
          contratosStore.zonificaciones[0].fecha_fin = contratosStore._header.fecha_fin
        }
      }
      onMounted(() => {
        contratosStore.limpiarData().then(() => {
          contratosStore.obtenerClientes()
          contratosStore.obtenerMotivosAutorizacion()
          contratosStore.listarModalidadesContrato()
          if (route.query.copiar) {
            contratosStore.findContrato(route.query.copiar).then((data) => {
              contratosStore.soportes = []
              contratosStore.header.NumCto = ''
              contratosStore.paginarPacientes(route.query.copiar, {}).then((data) => {
                if (data.rows.length) {
                  contratosStore.pacientes_ingresados = []
                  for (const i of data.rows) {
                    contratosStore.pacientes_ingresados.push({
                      TipoIdent: i.Paciente.U_ACS_TpoIdentf,
                      NoIdent: i.Paciente.U_ACS_NmrIdentf,
                      FechaInicio: i.FecInicio,
                      FechaFin: i.FecFin
                    })
                  }
                }
              })
              contratosStore.paginarArticulos(route.query.copiar, {}).then((data) => {
                if (data.rows.length) {
                  contratosStore.articulos.articulos = []
                  for (const i of data.rows) {
                    contratosStore.articulos.articulos.push(i.ItemCode)
                  }
                }
              })
              contratosStore.obtenerMotivosAutorizacion().then((info) => {
                contratosStore.modalidad_contrato_seleccionada = info.find(a => a.Id === data.CtoMotAutoriza[0].MotAutorizaId).CodModContrato
                listadoMotivosAutorizacion.value = contratosStore._listado_motivos_autorizacion.filter(a => a.CodModContrato === contratosStore._modalidad_contrato_seleccionada)
                motivosAutorizacionFiltrados()
              })
            })
          }
        })
      })
      return {
        listadoMotivosAutorizacion,
        listadoRegimenes,
        ingresarRegimen,
        regimenSeleccionado,
        eliminarRegimen,
        regimenesSeleccionados,
        contratosStore,
        motivosAutorizacionFiltrados,
        crearContrato,
        modalBorrador,
        guardarContrato,
        buscarEps,
        epsSeleccionada,
        agregarEps,
        eliminarEps,
        validarFechasZonificacion
      }
    }
  }
</script>
<style scoped>
  .border-input {
    padding: 0.45rem 0.75rem;
    border: 1px solid #ced4da;
  }
  ::-webkit-scrollbar {
    width: 0.4rem;
    margin: 1rem;
    padding: 1rem;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    border-radius: 9999px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  ::-webkit-input-placeholder { /* Edge */
    color: gray;
  }
</style>
